.prc-logo {
    position: relative;
    left: 0px;
    top: 0px;
    z-index: 1200;
    height: 100px;
    width: 100px;
    float: left;
    margin-right: 50px;
}

.header-text {
    position: relative;
    float: left;
    font-size: x-large;
}

.prc-page {
    width: 10.5in;
    height: 7.0in;
    padding: 10px;
    page-break-after: auto;
    left: 4px;
    border: 1px solid #999;
}
.prc-page-2 {
    width: 10.5in;
    height: 7.0in;
    padding: 10px;
    page-break-before: always;
    left: 4px;
    border: 1px solid #999;
}
.prc-page-3 {
    width: 10.5in;
    height: 7.0in;
    padding: 10px;
    page-break-before: always;
    left: 4px;
    border: 1px solid #999;
}
.cards-group {
    float: right;
    margin-right: 45px;
}

.prc-table {
    border-collapse: collapse;
    width: 100%;
}

.prc-row {
    border-bottom: 1px solid #CAC8C8;
    margin-bottom: 10px;
    height:19px;
}
.prc-sketch-column {
    margin-bottom: 10px;
    font-size: 0.6em;
    text-align: left;
    min-width: 16%;
    align-content: flex-start;
    border-right: 1px dashed #858484;
}
.prc-sketch-column td {
    color: #FFF;
    -webkit-text-stroke: 1px ;
}

.prc-key {
    color: #CAC8C8;
    font-weight: bold;
    font-size: 0.8em;
    width: 0.8in;
    border-left: 1px dashed #858484;
}
.prc-key::after {
    content: ': ';
}
.prc-value {
    margin-bottom: 10px;
    font-size: 0.7em;
    text-align: left;
    width: 1.1in;
    align-content: flex-start;
}
.prc-taxyr {
    position: relative;
    top: 20px;
    right: 20px;
    height: 30px;
    float: right;
}
.page-align {
    width: 11.5in;
    margin: 0 auto;
}


.sketch-row {
    width: 11in;
    height: 2in;
}
.sketch-area {
    min-width: 3.5in;
    border-right: 1px solid #999;
    float: left;
    height: 2.0in;
}
.print-btn {
    float: right;
}
.prc-button-header{
    width: 100%;
    height: 40px;
}

@media print {
    @page {
        size: landscape;
    }
    .mapboxgl-control-container {
        visibility: hidden;
    }
    .print-btn {
        visibility: hidden;
    }
    .prc-button-header {
        visibility: hidden;
    }
    .prc-sketch-column {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
    }

    .page-align {
        width: 11.5in;
        margin: 0 auto;
    }
    
    .prc-page {
        width: 10.5in;
        height: 7.0in;
        padding: 10px;
        page-break-after: auto;
        left: 4px;
        border: 1px solid #999;
    }
    .prc-page-2 {
        width: 10.5in;
        height: 7.0in;
        padding: 10px;
        page-break-before: always;
        left: 4px;
        border: 1px solid #999;
    }
    .prc-page-3 {
        width: 10.5in;
        height: 7.0in;
        padding: 10px;
        page-break-before: always;
        left: 4px;
        border: 1px solid #999;
    }
}
