@media (prefers-color-scheme: light) {
    body, .map-container {
      background: rgb(243, 235, 235);
      color: #333;
    }
  }
  @media (prefers-color-scheme: dark) {
    body, .map-container {
      background: #333;
      color: #FFF;
    }
  }